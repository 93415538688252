@use '@onfido/castor';

.introVideo {
  position: relative;
  margin: 0 auto;
  font-size: 0;
}

.videoWrapper {
  background: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-size: 0;
  overflow: hidden;
  border: 0;

  &:active {
    background: none;
    box-shadow: none;
  }

  &:focus,
  &:focus-visible {
    outline: 2px solid var(--osdk-color-border-button-primary-active);
    outline-offset: -1px;
    border-radius: var(--ods-border-radius-small);
  }
}

.video {
  max-width: 100%;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.button {
  position: absolute;
  right: castor.space(1);
  bottom: castor.space(1);
  width: 48px;
  height: 48px;
  border-radius: 50% !important;
  padding: 0;
  animation: fadeIn 0.2s forwards;

  svg {
    width: 40px;
    height: 40px;
  }
}
